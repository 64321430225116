import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Row, Col } from 'react-styled-flexboxgrid'
import { Button, Markdown } from '../components'
import { PageLayout } from '../layouts'
import * as styles from '../styles/pages/contact-us'
import system10 from '../../assets/system10.svg'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function ContactUs(props) {
  const { data } = props
  const { allYaml } = data
  const { edges } = allYaml
  const { node } = _.first(edges)
  return (
    <PageLayout
      description={node.metaDescription}
      sharingTitle={node.title}
    >
      <Helmet>
        <title>{node.title}</title>
      </Helmet>
      <styles.Container>
        <styles.TextWrapper>
          <Row>
            <styles.InfoCol sm={7} xs={12}>
              <styles.Header>
                {node.header}
              </styles.Header>
              <styles.Text>
                <Markdown>
                  {node.contactUsInfo}
                </Markdown>
              </styles.Text>
            </styles.InfoCol>
            <styles.FormCol sm={5} lg={4} xs={12}>
              <styles.PosedFormContainer>
                <styles.FormInfoText>
                  <Markdown>
                    {node.contactUsFormInfo}
                  </Markdown>
                </styles.FormInfoText>
                <form method="POST" target="_blank" action="https://aito.us18.list-manage.com/subscribe/post">
                  <input type="hidden" name="u" value="e7ff1392b78623cfdcbda17c8" />
                  <input type="hidden" name="id" value="47f853f6e4" />
                  {/*
                  Autogenerated by mailchimp:
                  "Real people should not fill this in and expect good things - do not remove this
                  or risk form bot signups"
                  */}
                  <div style={{ position: 'absolute', left: '-5000px', ariaHidden: 'true' }}>
                    <input
                      type="text"
                      name="b_e7ff1392b78623cfdcbda17c8_305bbc6e91"
                      tabIndex="-1"
                    />
                  </div>
                  {/* These names need to match the configuration in Mailchimp */}
                  <Row>
                    <Col xs={6}>
                      <styles.LabelText htmlFor="FNAME">First name</styles.LabelText>
                      <styles.Input name="FNAME" type="text" required inputStyle="square" placeholder="First Name" />
                    </Col>
                    <Col xs={6}>
                      <styles.LabelText htmlFor="LNAME">Last name</styles.LabelText>
                      <styles.Input name="LNAME" type="text" required inputStyle="square" placeholder="Last Name" />
                    </Col>
                  </Row>
                  <styles.LabelText htmlFor="EMAIL">Email</styles.LabelText>
                  <styles.Input name="EMAIL" type="email" required inputStyle="square" placeholder="Email" />
                  <styles.LabelText htmlFor="COMPANY">Company</styles.LabelText>
                  <styles.Input name="COMPANY" type="text" required inputStyle="square" placeholder="Company" />
                  <p>
                    By hitting the button you accept our
                    {' '}
                    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                      privacy policy
                    </a>
                    .
                  </p>
                  <Button size="small" type="submit">Contact me now!</Button>
                </form>
              </styles.PosedFormContainer>
            </styles.FormCol>
            <Col sm={0} lg={1} xs={0} />
          </Row>
          <styles.SquareImage1 src={system10} />
          <styles.SquareImage2 src={system10} />
        </styles.TextWrapper>
      </styles.Container>
    </PageLayout>
  )
}

ContactUs.propTypes = propTypes

export const contentQuery = graphql`
  query GetContactUsContent {
    allYaml(filter: {file: {eq: "contact-us"}}) {
      edges {
        node {
          title
          header
          metaDescription
          contactUsInfo
          contactUsFormInfo
        }
      }
    }
  }
`
