import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import { Heading, Checkbox as BaseCheckbox, Input as BaseInput } from '../../components'
import variables from '../variables'
import { TextWrapper as BaseTextWrapper } from '../base'

export const PosedFormContainer = styled.div`
  animation: fadeIn 0.7s ease-in-out;
`

export const Container = styled.div`
  width: 100%;
  background: ${variables.colors.silverTree.darker};
  color: white;

  p {
    color: ${variables.colors.silverTree.light};
    max-width: 560px;
  }

  a, a:visited, a:hover {
    color: currentColor;
    text-decoration: underline;
  }
`

export const FormCol = styled(Col)`
  padding-top: 50px;
`

export const InfoCol = styled(Col)`
  padding-right: 40px;
`

export const Header = styled(Heading)`
  animation: slideUp 0.5s ease-in-out;
`
export const Text = styled.div`
  animation: slideUp 0.5s ease-in-out 0.2s;
  animation-fill-mode: forwards;  
  opacity: 0;
`
export const TextWrapper = styled(BaseTextWrapper)`
  padding-top: 100px !important;
  padding-bottom: 240px !important;
  position: relative;

  @media ${variables.media.sm} {
    padding-bottom: 160px !important;
  }
`

export const FormInfoText = styled.div`
  font-size: 1.15rem;
  margin-bottom: 40px;
`

export const Checkbox = styled(BaseCheckbox)`
  color: ${variables.colors.silverTree.darker};
`

export const SquareImage1 = styled.img`
  position: absolute;
  z-index: 1;
  width: 250px;
  height: 250px;
  transform: translateY(60%) rotate(45deg);
  bottom: 0;
  left: 30px;
`

export const SquareImage2 = styled(SquareImage1)`
  left: auto;
  z-index: 1;
  left: 345px;
  width: 50px;
  height: 50px;
  bottom: 0;
  transform: translateY(70%) rotate(-135deg);
`

export const LabelText = styled.label`
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
`

export const Input = styled(BaseInput)`
  max-width: 100%;
`
